import {ScrollShadow, Link} from "@nextui-org/react";

import {NEWS_TYPE} from '@/components/helpers/constant.js';

var _ = require('lodash');
import SharedServiceInstance from "@/services/shared-service";
import SharedService from "@/services/shared-service";

export default function Documentation(documentationData) {
    const newsDatas = documentationData.data

    if (!newsDatas || newsDatas.total === 0) {
        return (<></>)
    }

    return (
        <div className="p-5 pb-0 h-full w-full text-center !bg-background/80 dark:bg-background/20 !bg-white/85 !bg-cover !bg-[url('/webp/nha-chung.webp')] !bg-blend-lighten rounded-[5px]">
            <Link href={`/chuyen-de/${NEWS_TYPE.MATERIAL_LIST.code}`}>
                <h1 className="text-2xl text-primary font-extrabold pb-5">
                    {NEWS_TYPE.MATERIAL_LIST.name.toLocaleUpperCase()}
                </h1>
            </Link>
            <ScrollShadow hideScrollBar className="w-full h-[400px]">
                <div className="divide-y-1">
                    {
                        newsDatas.value.map((item, index) => {
                            return (
                                <Link key={item.id} className="text-link w-full py-3 text-left"
                                      href={SharedService.getPostPath(item)}
                                >
                                    {item.title} - {SharedServiceInstance.formatISODate(item.created) || ''}
                                </Link>
                            );
                        })
                    }
                </div>
            </ScrollShadow>
        </div>
    );
}